import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import { useRef, useState } from "react"
import * as getCaretCoordinates from "textarea-caret"

const BRACKETS_REGEX = new RegExp("{{\\s*$")

export default function AutoMessageTextField({
  text,
  handleTextChanged,
  textInputRef,
  autoCompleteMenuPos,
  setAutoCompleteMenuPos,
  handleAutoCompleteOptionSelected,
}) {
  return (
    <>
      <TextField
        label="Text"
        placeholder="Use square brackets to refer to agent values such as {{ first_name }}"
        value={text}
        onChange={handleTextChanged}
        multiline
        rows={3}
        required
        inputRef={textInputRef}
      />
      <Menu
        open={!!autoCompleteMenuPos}
        onClose={() => setAutoCompleteMenuPos(null)}
        anchorReference="anchorPosition"
        anchorPosition={autoCompleteMenuPos}
      >
        {AUTOCOMPLETE_OPTIONS.map((option, index) => (
          <MenuItem
            key={index}
            onClick={handleAutoCompleteOptionSelected(option.fieldName)}
          >
            <ListItemText
              primary={option.label}
              secondary={`{{ ${option.fieldName} }}`}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export function useAutoMessageTextFieldState() {
  const [text, setText] = useState("")
  const [autoCompleteMenuPos, setAutoCompleteMenuPos] = useState(null)
  const textInputRef = useRef(null)

  function handleTextChanged(e) {
    setText(e.target.value)
    const localCaretPos = getCaretCoordinates(e.target, e.target.selectionEnd)
    const fieldBoundingRect = e.target.getBoundingClientRect()
    if (
      BRACKETS_REGEX.test(e.target.value.substring(0, e.target.selectionEnd))
    ) {
      setAutoCompleteMenuPos({
        top: localCaretPos.top + fieldBoundingRect.top,
        left: localCaretPos.left + fieldBoundingRect.left,
      })
    } else {
      setAutoCompleteMenuPos(null)
    }
  }

  function handleAutoCompleteOptionSelected(autoCompleteText) {
    return () => {
      setAutoCompleteMenuPos(null)
      const selectionEnd = textInputRef.current.selectionEnd
      setText(
        text.substring(0, selectionEnd) +
          ` ${autoCompleteText} }}` +
          text.substring(selectionEnd, text.length)
      )
    }
  }

  return {
    text,
    setText,
    handleTextChanged,
    textInputRef,
    autoCompleteMenuPos,
    setAutoCompleteMenuPos,
    handleAutoCompleteOptionSelected,
  }
}

class AutocompleteOption {
  constructor(label, fieldName) {
    this.label = label
    this.fieldName = fieldName
  }
}

const AUTOCOMPLETE_OPTIONS = [
  new AutocompleteOption("First name", "first_name"),
  new AutocompleteOption("Last name", "last_name"),
  new AutocompleteOption("Full name", "full_name"),
]

import AddIcCallIcon from "@mui/icons-material/AddIcCallOutlined"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useState } from "react"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import Column from "../../../common/components/Column"
import LoadingButton from "../../../common/components/LoadingButton"
import { isEnterKeyEvent, useIsMobile } from "../../../common/utils"
import VerifyPhoneCodeDialog from "../../users/components/VerifyPhoneCodeDialog"
import {
  usePhoneInputState,
  useSendPhoneVerificationSMS,
} from "../../users/utils"

export default function EnterPhoneDialog({ open, onClose, onPhoneVerified }) {
  const { phone, setPhone, phoneError, setPhoneError } = usePhoneInputState()
  const { isSendingPhoneVerification, sendVerification } =
    useSendPhoneVerificationSMS()
  const [isVerifyPhoneCodeDialogOpen, setIsVerifyPhoneCodeDialogOpen] =
    useState(false)
  const isMobile = useIsMobile()

  const isSubmitButtonEnabled = phone.length > 5

  function handleNextButtonClicked() {
    sendVerification(phone, setPhoneError).then(() => {
      onClose()
      setIsVerifyPhoneCodeDialogOpen(true)
    })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      fullScreen={isMobile}
      keepMounted
      sx={{ zIndex: 10000 }}
    >
      <DialogContent>
        <Column sx={{ alignItems: "center", textAlign: "center" }}>
          <HaloCircularIcon Icon={AddIcCallIcon} />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Verify your phone number
          </Typography>
          <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
            Gain access to Conversations and more by verifying your phone now.
          </Typography>
          <TextField
            label="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            error={!!phoneError}
            helperText={phoneError}
            onKeyDown={(e) =>
              isSubmitButtonEnabled &&
              isEnterKeyEvent(e) &&
              handleNextButtonClicked()
            }
            type="tel"
            fullWidth
            sx={{ mt: 4 }}
          />
        </Column>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          isLoading={isSendingPhoneVerification}
          onClick={handleNextButtonClicked}
          disabled={!isSubmitButtonEnabled}
        >
          Submit
        </LoadingButton>
      </DialogActions>
      <VerifyPhoneCodeDialog
        open={isVerifyPhoneCodeDialogOpen}
        onClose={() => setIsVerifyPhoneCodeDialogOpen(false)}
        phone={phone}
        handlePhoneVerified={onPhoneVerified}
      />
    </Dialog>
  )
}

export function useEnterPhoneDialogState() {
  const [isEnterPhoneDialogOpen, setIsEnterPhoneDialogOpen] = useState(false)

  return {
    isEnterPhoneDialogOpen,
    setIsEnterPhoneDialogOpen,
    handleEnterPhoneDialogClosed: () => setIsEnterPhoneDialogOpen(false),
  }
}

import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined"
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined"
import EmailIcon from "@mui/icons-material/EmailOutlined"
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import PersonAddOutline from "@mui/icons-material/PersonAddOutlined"
import PersonOutlinedIcon from "@mui/icons-material/PersonOutline"
import PhoneIcon from "@mui/icons-material/PhoneOutlined"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Popover from "@mui/material/Popover"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import BrandAvatar from "../../../common/components/BrandAvatar"
import BrokerageLocationUnknownWarning from "../../../common/components/BrokerageLocationUnknownWarning"
import BrokerageMediaGallery from "../../../common/components/BrokerageMediaGallery"
import CenterMapContainer from "../../../common/components/CenterMapContainer"
import Column from "../../../common/components/Column"
import IconTitleValue from "../../../common/components/IconTitleValue"
import JumpLink from "../../../common/components/JumpLink"
import LoadingButton from "../../../common/components/LoadingButton"
import LoadingWrapper, {
  CircularSkeleton,
  TextSkeleton,
} from "../../../common/components/LoadingSkeleton"
import PerksColumns from "../../../common/components/PerksColumns"
import Row, { RowButColumnOnMobile } from "../../../common/components/Row"
import TitledSection, {
  BorderedTitledSection,
} from "../../../common/components/TitledSection"
import TwoColumnGrid from "../../../common/components/TwoColumnGrid"
import InboxWhiteIcon from "../../../common/resources/icons/inbox-white.png"
import InboxIcon from "../../../common/resources/icons/inbox.svg"
import {
  AGENT_REQUEST_STATUS_OPTIONS,
  arrayContains,
  brokerageDescriptionDisplay,
  brokerageSizeDisplay,
  segmentAnalytics,
  useAuthorizedAxiosClient,
  useIsMobile,
  yearFoundedDisplay,
} from "../../../common/utils"
import { selectIsAgent } from "../../users/slice"
import AgentRequestStatusChip from "../components/AgentRequestStatusChip"
import RevealIdentityDialog from "../components/reveal-identity-dialog"
import { selectShowBlueCheck } from "../slice"
import { useCancelBrokerageRequest, useNavigateToConversation } from "../utils"
import PathFromHomeToBrokerageMap from "./PathFromHomeToBrokerageMap"
import SendAnonymousRequestDialog from "./send-anonymous-request-dialog"

export default function BrokerageDetailsCommon({
  brokerageID,
  embedded = false,
}) {
  const { brokerage, setBrokerage, isLoading, loadBrokerage } =
    useLoadBrokerageDetails()
  const [sendAnonymousRequestDialogOpen, setSendAnonymousRequestDialogOpen] =
    useState(false)
  const [revealIdentityDialogOpen, setRevealIdentityDialogOpen] =
    useState(false)
  const [isRevealingIdentity, setIsRevealingIdentity] = useState(false)
  const [isCreatingConversation, setIsCreatingConversation] = useState(false)
  const [requestPendingButtonRef, setRequestPendingButtonRef] = useState()

  const axios = useAuthorizedAxiosClient()
  const navigate = useNavigate()
  const navigateToConversation = useNavigateToConversation()
  const userIsAgent = useSelector(selectIsAgent)
  const showBlueCheck = useSelector(selectShowBlueCheck)
  const isMobile = useIsMobile()

  function handleSendAnonymousRequestButtonClicked() {
    setSendAnonymousRequestDialogOpen(true)
  }

  function handleSendAnonymousRequestDialogClosed(data) {
    setSendAnonymousRequestDialogOpen(false)
    if (data) {
      setBrokerage({ ...brokerage, agent_request: data })
    }
  }

  function handleChatButtonClicked() {
    setIsCreatingConversation(true)
    axios
      .get(`/agents/api/start-conversation/${brokerage.id}/`)
      .then(({ data }) => navigateToConversation(data.id))
      .finally(() => setIsCreatingConversation(false))
  }

  function handleConfirmRevealIdentityButtonClicked() {
    setIsRevealingIdentity(true)
    axios
      .put(`/agents/api/reveal-identity/${brokerage.id}/`)
      .then(({ data }) => {
        setBrokerage({
          ...brokerage,
          agent_request: data,
        })
        setRevealIdentityDialogOpen(false)
      })
      .finally(() => setIsRevealingIdentity(false))
  }

  function handleCancelRequestPopoverClosed(result) {
    if (result) {
      setBrokerage({ ...brokerage, agent_request: null })
    }
    setRequestPendingButtonRef(null)
  }

  function handleRevealIdentityDialogClosed(data) {
    setRevealIdentityDialogOpen(false)
  }

  const AVATAR_SIZE = 160
  const agentRequest = brokerage.agent_request
  const agentSentRequest = !!agentRequest
  const agentCanChat =
    agentSentRequest &&
    [
      AGENT_REQUEST_STATUS_OPTIONS.accepted,
      AGENT_REQUEST_STATUS_OPTIONS.inContact,
      AGENT_REQUEST_STATUS_OPTIONS.proposalReceived,
    ].indexOf(agentRequest.status) !== -1

  useEffect(
    () => {
      loadBrokerage(brokerageID)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(
    () => {
      if (isLoading || !userIsAgent) {
        return
      }
      segmentAnalytics.track("Agent visited brokerage page", {
        "Brokerage ID": brokerageID,
        "Brokerage name": brokerage.company,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, userIsAgent]
  )

  return (
    <Column>
      <CenterMapContainer
        Map={
          brokerage.location
            ? PathFromHomeToBrokerageMap
            : () => (
                <BrokerageLocationUnknownWarning subtitle="Agents won't be able to see your brokerage or send you requests. Please update your address on your brokerage page." />
              )
        }
        mapProps={{ brokerage: brokerage, isLoading: isLoading }}
        sx={{ height: "320px" }}
      />
      <RowButColumnOnMobile
        sx={{
          position: "relative",
          px: embedded ? 4 : 8,
          alignItems: { xs: "center", sm: "flex-start" },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: -20,
          }}
        >
          <LoadingWrapper
            isLoading={isLoading}
            skeleton={<CircularSkeleton size={AVATAR_SIZE} />}
          >
            <BrandAvatar
              brand={brokerage.image && { icon: brokerage.image }}
              size={AVATAR_SIZE}
              brokerageIconPadding={5}
              border="2px solid white"
            />
          </LoadingWrapper>
        </Box>
        <RowButColumnOnMobile
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            mt: { xs: 20, sm: 4 },
          }}
        >
          <Column
            sx={{
              alignItems: { xs: "center", sm: "flex-start" },
              ml: (theme) =>
                isMobile ? 0 : `calc(${AVATAR_SIZE}px + ${theme.spacing(2)})`,
            }}
          >
            {agentSentRequest && (
              <Box
                sx={{
                  mb: 1,
                }}
              >
                <AgentRequestStatusChip agentRequest={agentRequest} />
              </Box>
            )}
            <LoadingWrapper
              isLoading={isLoading}
              skeleton={<TextSkeleton width={12} height={3} />}
            >
              <Typography
                variant="h4"
                sx={{ textAlign: { xs: "center", sm: "left" } }}
              >
                {brokerage.company}
              </Typography>
            </LoadingWrapper>
            <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
              Brokerage
            </Typography>
          </Column>
          <Row
            sx={{
              alignItems: "center",
              ml: { xs: 0, sm: 2 },
              mt: { xs: 2, sm: 0 },
              "& .MuiButtonBase-root:nth-of-type(n + 2)": {
                ml: 2,
              },
            }}
          >
            {agentSentRequest && (
              <LoadingButton
                startIcon={
                  <img
                    src={agentCanChat ? InboxWhiteIcon : InboxIcon}
                    style={{ width: "20px", height: "20px" }}
                    alt="chat bubble"
                  />
                }
                isLoading={isCreatingConversation}
                disabled={!userIsAgent || !agentCanChat}
                onClick={agentCanChat ? handleChatButtonClicked : undefined}
              >
                Send message
              </LoadingButton>
            )}
            {!agentSentRequest && (
              <Button
                color="primary"
                variant="contained"
                startIcon={<PersonAddOutline />}
                onClick={handleSendAnonymousRequestButtonClicked}
                disabled={!userIsAgent || !showBlueCheck}
              >
                Send request
              </Button>
            )}
            {agentSentRequest &&
              arrayContains(
                [
                  AGENT_REQUEST_STATUS_OPTIONS.pending,
                  AGENT_REQUEST_STATUS_OPTIONS.rejected,
                ],
                agentRequest.status
              ) && (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AccessTimeOutlinedIcon />}
                  ref={requestPendingButtonRef}
                  onClick={(e) => setRequestPendingButtonRef(e.currentTarget)}
                  disabled={!userIsAgent}
                >
                  Pending
                </Button>
              )}
            <CancelRequestPopover
              anchorEl={requestPendingButtonRef}
              onClose={handleCancelRequestPopoverClosed}
              brokerage={brokerage}
            />
            {agentSentRequest && agentRequest.proposal_id && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  navigate(`/agent/proposals/${agentRequest.proposal_id}`)
                }
                disabled={!userIsAgent}
              >
                View proposal
              </Button>
            )}
          </Row>
        </RowButColumnOnMobile>
      </RowButColumnOnMobile>
      <Column sx={{ mt: 4, px: { xs: 2, sm: embedded ? 4 : 8 }, pt: 0, pb: 4 }}>
        <BorderedTitledSection title="Media">
          <LoadingWrapper
            isLoading={isLoading}
            skeleton={<Skeleton variant="rounded" height={192} width={192} />}
          >
            <BrokerageMediaGallery
              videos={brokerage.videos}
              images={brokerage.images}
            />
          </LoadingWrapper>
          <TitledSection title="Perks" addMarginTop>
            <PerksColumns perks={brokerage.perks} />
          </TitledSection>
          <Divider sx={{ mt: 4 }} />
          <TitledSection title="About" addMarginTop>
            <Row>
              <IconTitleValue
                Icon={PersonOutlinedIcon}
                title="Size"
                value={brokerageSizeDisplay(brokerage.head_count)}
              />
              <Box
                sx={{
                  ml: 4,
                }}
              >
                <IconTitleValue
                  Icon={CalendarTodayOutlinedIcon}
                  title="Year founded"
                  value={yearFoundedDisplay(brokerage.year_founded)}
                />
              </Box>
            </Row>
            <Typography variant="body1" sx={{ mt: 4 }}>
              {brokerageDescriptionDisplay(brokerage.description)}
            </Typography>
          </TitledSection>
          <Divider sx={{ mt: 4 }} />
          <TitledSection title="Contacts" addMarginTop>
            <TwoColumnGrid>
              <Grid xs={12} lg={6}>
                <IconTitleValue
                  Icon={LanguageOutlinedIcon}
                  title="Website"
                  value={
                    <JumpLink
                      title={brokerage.website}
                      url={brokerage.website}
                    />
                  }
                />
              </Grid>
              <Grid xs={12} lg={6}>
                <IconTitleValue
                  Icon={EmailIcon}
                  title="Email"
                  value={brokerage.email}
                />
              </Grid>
              <Grid xs={12} lg={6}>
                <IconTitleValue
                  Icon={LocationOnOutlinedIcon}
                  title="Location"
                  value={brokerage.address}
                />
              </Grid>
              <Grid xs={12} lg={6}>
                <IconTitleValue
                  Icon={PhoneIcon}
                  title="Phone"
                  value={brokerage.phone}
                />
              </Grid>
            </TwoColumnGrid>
          </TitledSection>
        </BorderedTitledSection>
      </Column>
      <SendAnonymousRequestDialog
        open={sendAnonymousRequestDialogOpen}
        onClose={handleSendAnonymousRequestDialogClosed}
        brokerage={brokerage}
      />
      <RevealIdentityDialog
        open={revealIdentityDialogOpen}
        onClose={handleRevealIdentityDialogClosed}
        onRevealButtonClicked={handleConfirmRevealIdentityButtonClicked}
        isLoading={isRevealingIdentity}
      />
    </Column>
  )
}

function CancelRequestPopover({ anchorEl, onClose, brokerage }) {
  const { isCancelling, cancelBrokerageRequest } = useCancelBrokerageRequest()

  function handleCancelButtonClicked() {
    cancelBrokerageRequest(brokerage.agent_request).then(() => onClose(true))
  }

  return (
    <Popover
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={() => onClose()}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      <Column sx={{ alignItems: "flex-start", p: 2, maxWidth: "300px" }}>
        <Typography variant="h6">Your request is pending</Typography>
        <Typography variant="body1" color="text.secondary2">
          The brokerage has not yet accepted your request to receive an offer.
        </Typography>
        <LoadingButton
          variant="outlined"
          color="primary"
          isLoading={isCancelling}
          sx={{ mt: 2 }}
          onClick={handleCancelButtonClicked}
        >
          Cancel request
        </LoadingButton>
      </Column>
    </Popover>
  )
}

export function useLoadBrokerageDetails() {
  const [isLoading, setIsLoading] = useState(true)
  const [brokerage, setBrokerage] = useState({
    images: [],
    videos: [],
    perks: [],
    salary_range: [0, 0],
  })
  const axios = useAuthorizedAxiosClient()

  function loadBrokerage(id) {
    setIsLoading(true)
    axios
      .get(`/agents/api/brokerages/${id}/`)
      .then(({ data }) => setBrokerage(data))
      .finally(() => setIsLoading(false))
  }

  return {
    isLoading,
    brokerage,
    setBrokerage,
    loadBrokerage,
  }
}

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined"
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined"
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import QuickreplyOutlinedIcon from "@mui/icons-material/QuickreplyOutlined"
import SellOutlinedIcon from "@mui/icons-material/SellOutlined"
import LoadingButton from "@mui/lab/LoadingButton"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Divider from "@mui/material/Divider"
import FormControl from "@mui/material/FormControl"
import InputAdornment from "@mui/material/InputAdornment"
import InputLabel from "@mui/material/InputLabel"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import { styled } from "@mui/material/styles"
import { useEffect, useState } from "react"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import Column from "../../../common/components/Column"
import LengthLimitedTextField from "../../../common/components/LengthLimitedTextField"
import { useIsMobile } from "../../../common/utils"
import { useAuthorizedAxiosClient } from "../../../common/utils"
import AutoMessageTextField, {
  useAutoMessageTextFieldState,
} from "../components/AutoMessageTextField"

const STATUS_CHOICES = [
  { id: "draft", label: "Draft" },
  { id: "live", label: "Live" },
]

export default function AddOrUpdateAutoMessageDialog({
  open,
  onClose,
  existingAutoMessage,
}) {
  const [title, setTitle] = useState("")
  const {
    text,
    setText,
    handleTextChanged,
    textInputRef,
    autoCompleteMenuPos,
    setAutoCompleteMenuPos,
    handleAutoCompleteOptionSelected,
  } = useAutoMessageTextFieldState()
  const DEFAULT_STATUS = STATUS_CHOICES[0].id
  const [status, setStatus] = useState(DEFAULT_STATUS)
  const [yearsInBusiness, setYearsInBusiness] = useState(0)
  const [salesVolume, setSalesVolume] = useState(0)
  const [homesSold, setHomesSold] = useState(0)
  const [signingBonus, setSigningBonus] = useState(0)
  const [isCreating, setIsCreating] = useState(false)
  const [isUpdatingOrDeleting, setIsUpdatingOrDeleting] = useState(false)
  const [actionButtonMenuRef, setActionButtonMenuRef] = useState(null)
  const [actionButtonMode, setActionButtonMode] = useState(
    ACTION_BUTTON_MODES.update
  )
  const axios = useAuthorizedAxiosClient()
  const isMobile = useIsMobile()
  const isUpdating = actionButtonMode === ACTION_BUTTON_MODES.update
  const actionButtonDisabled = !title || !text
  const apiData = {
    title,
    text,
    status,
    years_in_business: yearsInBusiness,
    sales_volume_last_year: salesVolume,
    homes_sold_last_year: homesSold,
    signing_bonus: signingBonus,
  }

  function handleSoftClose() {
    onClose()
    clearFields()
  }

  function clearFields() {
    updateFields("", "", DEFAULT_STATUS, 0, 0, 0, 0, ACTION_BUTTON_MODES.update)
  }

  function updateFields(
    title,
    text,
    status,
    yearsInBusiness,
    salesVolume,
    homesSold,
    signingBonus,
    actionButtonMode = ACTION_BUTTON_MODES.update
  ) {
    setTitle(title)
    setText(text)
    setStatus(status)
    setYearsInBusiness(yearsInBusiness)
    setSalesVolume(salesVolume)
    setHomesSold(homesSold)
    setSigningBonus(signingBonus)
    setActionButtonMode(actionButtonMode)
  }

  function handleCreateButtonClicked() {
    setIsCreating(true)
    axios
      .post("/customers/api/text-message-template/", apiData)
      .then(({ data }) => {
        clearFields()
        onClose(data)
      })
      .finally(() => setIsCreating(false))
  }

  function handleUpdateOrDeleteButtonClicked() {
    setIsUpdatingOrDeleting(true)
    let apiPromise
    const apiURL = `/customers/api/text-message-template/${existingAutoMessage.id}/`
    if (isUpdating) {
      apiPromise = axios.put(apiURL, apiData)
    } else {
      apiPromise = axios.delete(apiURL)
    }
    apiPromise
      .then(({ data }) => {
        onClose(data, actionButtonMode)
        clearFields()
      })
      .finally(() => setIsUpdatingOrDeleting(false))
  }

  function handleActionMenuItemClicked(mode) {
    setActionButtonMode(mode)
    closeActionMenu()
  }

  function closeActionMenu() {
    setActionButtonMenuRef(null)
  }

  useEffect(
    () => {
      if (!existingAutoMessage) {
        return
      }
      updateFields(
        existingAutoMessage.title,
        existingAutoMessage.text,
        existingAutoMessage.status,
        existingAutoMessage.years_in_business,
        existingAutoMessage.sales_volume_last_year,
        existingAutoMessage.homes_sold_last_year,
        existingAutoMessage.signing_bonus
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [existingAutoMessage]
  )

  return (
    <Dialog
      open={open}
      onClose={handleSoftClose}
      maxWidth="sm"
      fullWidth={!isMobile}
    >
      <DialogContent sx={{ pr: 1 }}>
        <Column>
          <Column sx={{ alignItems: "center" }}>
            <HaloCircularIcon Icon={QuickreplyOutlinedIcon} size={64} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              {existingAutoMessage ? "Update" : "Create New"} Auto Message
            </Typography>
          </Column>
          <Column sx={{ mt: 4, gap: 3 }}>
            <LengthLimitedTextField
              label="Title"
              value={title}
              setValue={setTitle}
              maxLength={128}
              required
            />
            <AutoMessageTextField
              text={text}
              handleTextChanged={handleTextChanged}
              textInputRef={textInputRef}
              autoCompleteMenuPos={autoCompleteMenuPos}
              setAutoCompleteMenuPos={setAutoCompleteMenuPos}
              handleAutoCompleteOptionSelected={
                handleAutoCompleteOptionSelected
              }
            />
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                {STATUS_CHOICES.map((choice) => (
                  <MenuItem value={choice.id}>{choice.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Divider sx={{ mt: 1 }} />
            <Typography variant="h6">Agent Criteria</Typography>
            <Grid container rowSpacing={3} columnSpacing={2} xs={12}>
              <StatGridItem>
                <TextField
                  label="Years in business"
                  value={yearsInBusiness}
                  onChange={(e) => setYearsInBusiness(e.target.value)}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarTodayOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </StatGridItem>
              <StatGridItem>
                <TextField
                  label="Sales volume ($)"
                  value={salesVolume}
                  onChange={(e) => setSalesVolume(e.target.value)}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </StatGridItem>
              <StatGridItem>
                <TextField
                  label="Homes sold"
                  value={homesSold}
                  onChange={(e) => setHomesSold(e.target.value)}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HomeOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </StatGridItem>
              <StatGridItem>
                <TextField
                  label="Signing bonus ($)"
                  value={signingBonus}
                  onChange={(e) => setSigningBonus(e.target.value)}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SellOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </StatGridItem>
            </Grid>
          </Column>
        </Column>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleSoftClose}>
          Cancel
        </Button>
        {existingAutoMessage ? (
          <ButtonGroup>
            <LoadingButton
              variant="contained"
              color={isUpdating ? "primary" : "error"}
              loading={isUpdatingOrDeleting}
              disabled={actionButtonDisabled}
              onClick={handleUpdateOrDeleteButtonClicked}
              sx={{ flexGrow: 2 }}
            >
              {actionButtonMode}
            </LoadingButton>
            <Button
              size="small"
              variant="contained"
              color={isUpdating ? "primary" : "error"}
              onClick={(e) => setActionButtonMenuRef(e.currentTarget)}
              sx={{ maxWidth: "48px!important" }}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
        ) : (
          <LoadingButton
            variant="contained"
            color="primary"
            loading={isCreating}
            disabled={actionButtonDisabled}
            onClick={handleCreateButtonClicked}
          >
            Create
          </LoadingButton>
        )}
        <Menu
          anchorEl={actionButtonMenuRef}
          open={!!actionButtonMenuRef}
          onClose={closeActionMenu}
          anchorOrigin={{
            horizontal: "center",
            vertical: "bottom",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
        >
          {Object.values(ACTION_BUTTON_MODES).map((mode) => (
            <MenuItem
              key={mode}
              onClick={() => handleActionMenuItemClicked(mode)}
              selected={mode === actionButtonMode}
            >
              {mode}
            </MenuItem>
          ))}
        </Menu>
      </DialogActions>
    </Dialog>
  )
}

const StatGridItem = styled((props) => <Grid xs={12} sm={6} {...props} />)(
  () => {}
)

export const ACTION_BUTTON_MODES = {
  update: "Update",
  delete: "Delete",
}

import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined"
import Chip from "@mui/material/Chip"
import Divider from "@mui/material/Divider"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { lighten, styled } from "@mui/material/styles"
import { loadStripe } from "@stripe/stripe-js"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"

import ChangePasswordForm from "../../../common/components/ChangePasswordForm"
import Column from "../../../common/components/Column"
import IconTitleValue from "../../../common/components/IconTitleValue"
import LoadingButton from "../../../common/components/LoadingButton"
import Row from "../../../common/components/Row"
import SettingsSection, {
  SettingsSectionContainer,
} from "../../../common/components/SettingsSection"
import TabContainer from "../../../common/components/TabContainer"
import { useAuthorizedAxiosClient } from "../../../common/utils"
import {
  profileLoading,
  selectCurrentBrokerage,
  selectIsFreeRiding,
} from "../slice"
import {
  electActiveSubscriptionForBrokerage,
  formatDate,
  useIsBrokerageSubscribed,
} from "../utils"
import AutoMessagingTab from "./AutoMessagingTab"
import PaymentMethodUpdatedDialog from "./PaymentMethodUpdatedDialog"

export default function Settings() {
  const TABS = {
    accountManagement: 0,
    autoMessages: 1,
    paymentMethod: 2,
  }
  const [selectedTab, setSelectedTab] = useState(TABS.accountManagement)
  const [isLoadingCheckoutSession, setIsLoadingCheckoutSession] =
    useState(false)
  const [isLoadingStripe, setIsLoadingStripe] = useState(false)
  const [stripe, setStripe] = useState(null)

  const currentBrokerage = useSelector(selectCurrentBrokerage)
  const subscription = electActiveSubscriptionForBrokerage(
    currentBrokerage,
    false
  )
  const isProfileLoading = useSelector(profileLoading)
  const axios = useAuthorizedAxiosClient()
  const searchParams = useSearchParams()[0]
  const { isBrokerageSubscribed } = useIsBrokerageSubscribed()
  const isFreeRiding = useSelector(selectIsFreeRiding)
  const showPaymentMethodTab =
    !isFreeRiding && isBrokerageSubscribed(currentBrokerage)

  function handleUpdatePaymentMethodButtonClicked() {
    setIsLoadingCheckoutSession(true)
    axios
      .get(`/billing/api/${currentBrokerage.id}/update-pm-checkout-session/`)
      .then(({ data }) =>
        stripe.redirectToCheckout({ sessionId: data.session_id })
      )
      .finally(() => {
        setIsLoadingCheckoutSession(false)
      })
  }

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }

      setSelectedTab(TABS.accountManagement)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading, currentBrokerage]
  )

  useEffect(() => {
    setIsLoadingStripe(true)
    loadStripe(process.env.REACT_APP_STRIPE_KEY)
      .then((stripe) => setStripe(stripe))
      .finally(() => setIsLoadingStripe(false))
  }, [])

  useEffect(
    () => {
      const tabParam = searchParams.get("tab")
      if (tabParam === "pm") {
        setSelectedTab(TABS.paymentMethod)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams]
  )

  return (
    <Column
      sx={{
        width: {
          xs: "100%",
          md: selectedTab === TABS.autoMessages ? "90%" : "800px",
        },
        transition: (theme) => theme.transitions.create(["width"]),
        mx: "auto",
        px: { xs: 2, md: 0 },
        py: 4,
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={(e, value) => setSelectedTab(value)}
        sx={{ alignSelf: "center" }}
      >
        <Tab label="Account management" />
        <Tab
          label={
            <>
              Auto Messaging{" "}
              <NewFeatureChip
                label="new"
                selected={selectedTab === TABS.autoMessages}
              />
            </>
          }
          sx={{ position: "relative" }}
        />
        {showPaymentMethodTab && <Tab label="Payment method" />}
      </Tabs>
      <TabContainer index={TABS.accountManagement} value={selectedTab}>
        <SettingsSectionContainer>
          <ChangePasswordForm />
        </SettingsSectionContainer>
      </TabContainer>
      <TabContainer index={TABS.autoMessages} value={selectedTab}>
        <AutoMessagingTab />
      </TabContainer>
      {showPaymentMethodTab && (
        <TabContainer index={TABS.paymentMethod} value={selectedTab}>
          <SettingsSection
            title="Payment method"
            subtitle={`Your next billing date is ${
              subscription?.valid_until
                ? formatDate(subscription.valid_until)
                : "not known"
            }.`}
          >
            <Divider sx={{ mt: 4 }} />
            <Row
              sx={{
                mt: 4,
              }}
            >
              <IconTitleValue
                Icon={() => <PaymentOutlinedIcon fontSize="large" />}
                title="Payment method"
                value={`**** ${subscription?.card_data?.last4 ?? "Unknown"}`}
              />
            </Row>
            <LoadingButton
              disabled={isLoadingStripe}
              isLoading={isLoadingCheckoutSession}
              onClick={handleUpdatePaymentMethodButtonClicked}
              sx={{ mt: 4 }}
            >
              Update payment method
            </LoadingButton>
          </SettingsSection>
        </TabContainer>
      )}
      <PaymentMethodUpdatedDialog />
    </Column>
  )
}

const NewFeatureChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ theme, selected }) => ({
  backgroundColor: selected
    ? theme.palette.primary.main
    : lighten(theme.palette.primary.main, 0.25),
  color: theme.palette.primary.contrastText,
  height: "12px",
  fontSize: "8px",
  fontWeight: 600,
  "& .MuiChip-label": {
    paddingInline: theme.spacing(0.5),
  },
  position: "absolute",
  top: 0,
  right: 0,
}))

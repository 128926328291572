import CircularProgress from "@mui/material/CircularProgress"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import CircularProgressBackdrop from "../../../common/components/CircularProgressBackdrop"
import Column from "../../../common/components/Column"
import LoadingWrapper, {
  TextSkeleton,
} from "../../../common/components/LoadingSkeleton"
import { useAuthorizedAxiosClient } from "../../../common/utils"
import {
  WhiteButton,
  WhiteHeader,
  WhiteOutlinedButton,
  WhiteSubtitle,
} from "../components/White"
import UpgradeProDialogBackgroundImage from "../resources/images/upgrade-pro-dialog-bg.png"
import { updateBrokerageSubscription } from "../slice"
import {
  electActiveSubscriptionForBrokerage,
  formatDate,
  getTogglePlanData,
} from "../utils"
import BrokeragePaper from "./BrokeragePaper"

export default function SwitchPlanDialog({ open, onClose, brokerage }) {
  const [upcomingInvoice, setUpcomingInvoice] = useState({
    price: 0,
    date: new Date().toString(),
  })
  const [isLoadingUpcomingInvoice, setIsLoadingUpcomingInvoice] = useState(true)
  const [isSwitchingPlan, setIsSwitchingPlan] = useState(false)

  const axios = useAuthorizedAxiosClient()
  const dispatch = useDispatch()
  const subscription = electActiveSubscriptionForBrokerage(brokerage, true)
  const togglePlanData = subscription && getTogglePlanData(subscription)

  function handleSwitchPlanButtonClicked() {
    setIsSwitchingPlan(true)
    axios
      .post(`/billing/api/${brokerage.id}/switch-plan/`)
      .then(({ data }) => {
        dispatch(
          updateBrokerageSubscription({
            brokerageID: brokerage.id,
            subscription: data,
          })
        )
        onClose()
      })
      .finally(() => setIsSwitchingPlan(false))
  }

  useEffect(
    () => {
      if (!open) {
        return
      }
      axios
        .get(`/billing/api/${brokerage.id}/switch-plan-upcoming-invoice/`)
        .then(({ data }) => setUpcomingInvoice(data))
        .finally(() => setIsLoadingUpcomingInvoice(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open]
  )

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          backgroundImage: `url(${UpgradeProDialogBackgroundImage})`,
          backgroundSize: "cover",
          borderRadius: (theme) => theme.units.borderRadius,
        },
      }}
    >
      {subscription && (
        <>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Column
              sx={{
                alignItems: "center",
                textAlign: "center",
                px: 4,
              }}
            >
              <WhiteHeader variant="h4">
                Switch to {togglePlanData.code} plan
              </WhiteHeader>
              <WhiteSubtitle variant="body1" sx={{ mt: 1 }}>
                Your current plan is active until{" "}
                {formatDate(subscription.valid_until)}. By switching to{" "}
                {togglePlanData.code}, your payment method will be{" "}
                <LoadingWrapper
                  isLoading={isLoadingUpcomingInvoice}
                  skeleton={<TextSkeleton width={10} height={1} />}
                >
                  {upcomingInvoice.price >= 0 ? "invoiced" : "credited"}
                </LoadingWrapper>{" "}
                <LoadingWrapper
                  isLoading={isLoadingUpcomingInvoice}
                  skeleton={<TextSkeleton width={5} height={1} />}
                >
                  $
                  {Math.abs(Math.round(upcomingInvoice.price)).toLocaleString()}
                </LoadingWrapper>{" "}
                approx. immediately. Your next invoice will be for $
                {togglePlanData.price.toLocaleString()} on{" "}
                <LoadingWrapper
                  isLoading={isLoadingUpcomingInvoice}
                  skeleton={<TextSkeleton width={15} height={1} />}
                >
                  {formatDate(upcomingInvoice.date)}
                </LoadingWrapper>
                .
              </WhiteSubtitle>
              <BrokeragePaper brokerage={brokerage} />
            </Column>
          </DialogContent>
          <DialogActions>
            <WhiteOutlinedButton variant="outlined" onClick={() => onClose()}>
              Cancel
            </WhiteOutlinedButton>
            <WhiteButton
              onClick={handleSwitchPlanButtonClicked}
              startIcon={
                isSwitchingPlan ? <CircularProgress size={16} /> : undefined
              }
              disabled={isSwitchingPlan}
            >
              Switch to {togglePlanData.code}
            </WhiteButton>
          </DialogActions>
        </>
      )}
      <CircularProgressBackdrop open={isLoadingUpcomingInvoice} />
    </Dialog>
  )
}

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutline"
import HourglassTopOutlinedIcon from "@mui/icons-material/HourglassTopOutlined"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"

import {
  DangerHaloCircularIcon,
  HaloCircularIcon,
} from "../../../common/components/CircularIcon"
import Column from "../../../common/components/Column"
import { profileLoading, selectBrokerageByID } from "../slice"
import {
  PAYMENT_INTENT_STATUSES,
  PLAN_DATA,
  electSubscriptionForBrokerage,
} from "../utils"

export default function PaymentStatusDialog({
  brokerage,
  onTrySubscribeAgain,
}) {
  const [subscribedBrokerage, setSubscribedBrokerage] = useState(null)
  const searchParams = useSearchParams()[0]
  const isProfileLoading = useSelector(profileLoading)
  const selectBrokerageByID_ = useSelector(selectBrokerageByID)

  function handleTryAgainLinkClicked() {
    handleDialogClosed()
    onTrySubscribeAgain()
  }

  let icon, title, subtitle
  if (subscribedBrokerage) {
    const subscription = electSubscriptionForBrokerage(
      subscribedBrokerage,
      "payment_intent_status",
      [PAYMENT_INTENT_STATUSES.succeeded, PAYMENT_INTENT_STATUSES.processing]
    )
    switch (subscription.payment_intent_status) {
      case PAYMENT_INTENT_STATUSES.succeeded:
        icon = <HaloCircularIcon Icon={CheckOutlinedIcon} />
        title = "Congratulations!"
        subtitle = `Your subscription has been upgraded to Pro ${
          PLAN_DATA[searchParams.get("plan")].display
        } Plan!`
        break
      case PAYMENT_INTENT_STATUSES.processing:
      case null:
        icon = <HaloCircularIcon Icon={HourglassTopOutlinedIcon} />
        title = "Your payment is being processed"
        subtitle = "Please wait a few minutes then try refreshing your browser."
        break
      default:
        icon = <DangerHaloCircularIcon Icon={ErrorOutlinedIcon} />
        title = "Payment failed"
        subtitle = (
          <>
            Your payment has failed.{" "}
            <Link
              href="#"
              onClick={handleTryAgainLinkClicked}
              color="primary"
              underline="always"
            >
              Click here
            </Link>{" "}
            to try again.
          </>
        )
        break
    }
  }

  function handleDialogClosed() {
    setSubscribedBrokerage(null)
  }

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      const brokerageIDParam = searchParams.get("bid")
      if (brokerageIDParam) {
        let brokerageID = parseInt(brokerageIDParam)
        if (brokerageID !== brokerage.id) {
          return
        }
        setSubscribedBrokerage(selectBrokerageByID_(brokerageID))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading, brokerage, searchParams]
  )

  return (
    <Dialog
      open={!!subscribedBrokerage}
      onClose={handleDialogClosed}
      maxWidth="xs"
      fullWidth
    >
      {subscribedBrokerage && (
        <>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Column
              sx={{
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {icon}
              <Typography variant="h5" sx={{ mt: 2 }}>
                {title}
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary2"
                sx={{ mt: 1 }}
              >
                {subtitle}
              </Typography>
            </Column>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDialogClosed}
            >
              Ok
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

import axios from "axios"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { useUpdateTourStatus } from "../../common/components/CommonTourProvider"
import { segmentAnalytics, useAuthorizedAxiosClient } from "../../common/utils"
import { createNotification } from "../notifications/utils"
import { USER_TYPES, mixpanelUserType } from "../users/utils"
import { selectProfileMissingProperties, updateProfile } from "./slice"

export class WebhookPayload {
  constructor(
    stepTitle,
    firstName,
    lastName,
    email,
    phone,
    zipcode,
    yearsInBusiness,
    homesSoldLastYear,
    salesVolumeLastYear,
    levelOfInterest,
    selectedBrokerageCount
  ) {
    this.stepTitle = stepTitle
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.phone = phone
    this.zipcode = zipcode
    this.yearsInBusiness = yearsInBusiness
    this.homesSoldLastYear = homesSoldLastYear
    this.salesVolumeLastYear = salesVolumeLastYear
    this.levelOfInterest = levelOfInterest
    this.selectedBrokerageCount = selectedBrokerageCount
  }
}

export function webhookTrack(payload: WebhookPayload) {
  axios.post("https://hook.us1.make.com/cp37so2g5l15c2kwu7koqo569idub8zx", {
    environment: process.env.REACT_APP_ENVIRONMENT,
    ...payload,
  })
}

export function useProfileNotificationRequirements() {
  const profileMissingProperties = useSelector(selectProfileMissingProperties)
  const profilePropertyDisplayNameMap = {
    profile_image: "Avatar",
    job_title: "Current position",
    mission_statement: "Mission statement",
    website: "Website",
    address: "Address",
    phone: "Phone",
    years_in_business: "Years in business",
    sales_volume_last_year: "Sales volume",
    homes_sold_last_year: "Homes sold",
  }
  const navigate = useNavigate()

  function getSubtitleMessage() {
    function getDisplayNameForPropertyIndex(index) {
      return profilePropertyDisplayNameMap[profileMissingProperties[index]]
    }
    let message = `Please add your ${getDisplayNameForPropertyIndex(0)}`
    if (profileMissingProperties.length > 1) {
      message += ` and ${getDisplayNameForPropertyIndex(1)}`
      if (profileMissingProperties.length > 2) {
        message += ` (plus ${profileMissingProperties.length - 2} more).`
      }
    }

    return message
  }

  function buttonHandler() {
    navigate("/agent/profile")
  }

  return {
    title: "Complete your profile",
    subtitle: getSubtitleMessage(),
    buttonHandler,
  }
}

export function useRateExperienceNotificationRequirements() {
  const title = "Rate your experience"
  const subtitle = "Your feedback is very important for us"

  return { title, subtitle }
}

export function useCancelBrokerageRequest() {
  const [isCancelling, setIsCancelling] = useState(false)
  const axios = useAuthorizedAxiosClient()

  function cancelBrokerageRequest(brokerageRequest) {
    setIsCancelling(true)
    return axios
      .post(`/agents/api/brokerage-request/cancel/${brokerageRequest.id}/`)
      .finally(() => setIsCancelling(false))
  }
  return { isCancelling, cancelBrokerageRequest }
}

export function useNavigateToConversation() {
  const navigate = useNavigate()
  return (conversationID) => {
    navigate(`/agent/conversations/?cid=${conversationID}`)
  }
}

export function useUpdateAgentTourStatus() {
  return useUpdateTourStatus(
    "/agents/api/profile/",
    "welcome_tour_status",
    updateProfile
  )
}

export function mixpanelUpdateAgent(
  id,
  {
    $first_name,
    $last_name,
    $name,
    $email,
    $phone,
    years_in_business,
    sales_volume_last_year,
    homes_sold_last_year,
    level_of_interest,
    zip_code,
    total_brokerage_count,
    selected_brokerage_count,
    distance_from_work,
  }
) {
  const agentData = { user_type: mixpanelUserType(USER_TYPES.agent) }
  const passedInData = arguments[1]
  for (let property in passedInData) {
    const propertyValue = passedInData[property]
    if (propertyValue) {
      agentData[property] = propertyValue
    }
  }
  segmentAnalytics.identify(id, agentData)
}

export const NOTIFICATION_TYPES = {
  completeProfile: "agent.complete_profile",
  verifyPhone: "agent.verify_phone",
  experienceRating: "agent.experience_rating",
  conversationNewMessages: "agent.conversation_new_messages",
  proposalReceived: "agent.proposal_received",
}

export const COMPLETE_PROFILE_NOTIFICATION = createNotification(
  NOTIFICATION_TYPES.completeProfile,
  NOTIFICATION_TYPES.completeProfile,
  true
)

export const BACKEND_AGENT_LEVELS_OF_INTEREST = {
  active: "active",
  undecided: "undecided",
  busy: "busy",
}

export const AGENT_LEVELS_OF_INTEREST = {
  [BACKEND_AGENT_LEVELS_OF_INTEREST.active]: "Looking to move",
  [BACKEND_AGENT_LEVELS_OF_INTEREST.undecided]: "Open to considering options",
  [BACKEND_AGENT_LEVELS_OF_INTEREST.busy]: "Not currently seeking",
}

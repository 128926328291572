import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import { useTheme } from "@mui/material/styles"

import FlexBackgroundCloseButton, {
  CloseIcon,
} from "../../../common/components/FlexBackgroundCloseButton"
import { useIsMobile } from "../../../common/utils"
import BrokerageDetailsCommon from "../../agent/brokerage-detail/BrokerageDetailCommon"

export default function PreviewBrokerageAsAgentDialog({
  open,
  onClose,
  brokerageID,
}) {
  const theme = useTheme()
  const isMobile = useIsMobile()

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="xl"
      fullWidth
      fullScreen={isMobile}
      sx={{
        "& .MuiDialog-paper": {
          p: 0,
        },
      }}
    >
      <Box sx={{ position: "relative" }}>
        <FlexBackgroundCloseButton
          sx={{ top: theme.spacing(2), right: theme.spacing(2), zIndex: 100 }}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </FlexBackgroundCloseButton>
        <BrokerageDetailsCommon brokerageID={brokerageID} embedded />
      </Box>
      <DialogActions
        sx={{ px: 4, pt: 0, "& .MuiButton-root": { flexBasis: "auto" } }}
      >
        <Button variant="contained" color="primary" onClick={() => onClose()}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDownOutlined"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined"
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined"
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined"
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import { styled, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom"

import BrandAvatar from "../../../common/components/BrandAvatar"
import Column from "../../../common/components/Column"
import EditableTextDisplay from "../../../common/components/EditableTextDisplay"
import JumpLink from "../../../common/components/JumpLink"
import Row, { RowButColumnOnMobile } from "../../../common/components/Row"
import TitledEditorSection from "../../../common/components/TitledEditorSection"
import { BorderedTitledSection } from "../../../common/components/TitledSection"
import UnsavedChangesPreventLeaveDialog from "../../../common/components/UnsavedChangesPreventLeaveDialog"
import UserAvatar from "../../../common/components/UserAvatar"
import { getFullName, useStateChangeTracker } from "../../../common/utils"
import SelectBrokerageDialog, {
  useSelectBrokerageDialogState,
} from "../claim-office/select-brokerage/Dialog"
import SubscriptionStatusChip from "../components/SubscriptionStatusChip"
import {
  profileLoading,
  selectBrokerages,
  selectIsFreeRiding,
  selectProfile,
  showProDialogForBrokerageIndex,
  updateProfile,
} from "../slice"
import {
  SUBSCRIPTION_STATUSES,
  electActiveSubscriptionForBrokerage,
  formatDate,
  getTogglePlanData,
  useIsBrokerageSubscribed,
} from "../utils"
import CancelSubscriptionDialog from "./CancelSubscriptionDialog"
import EditProfileDialog from "./EditProfileDialog"
import PaymentStatusDialog from "./PaymentStatusDialog"
import RestoreSubscriptionDialog from "./RestoreSubscriptionDialog"
import SwitchPlanDialog from "./SwitchPlanDialog"

export default function Profile() {
  const [isEditProfileDialogOpen, setIsEditProfileDialogOpen] = useState(false)
  const {
    state: phone,
    setState: setPhone,
    hasChanged: phoneChanged,
    handleChangeSaved: handlePhoneSaved,
  } = useStateChangeTracker("")
  const {
    state: website,
    setState: setWebsite,
    hasChanged: websiteChanged,
    handleChangeSaved: handleWebsiteSaved,
  } = useStateChangeTracker("")
  const {
    state: address,
    setState: setAddress,
    hasChanged: addressChanged,
    handleChangeSaved: handleAddressSaved,
  } = useStateChangeTracker("")
  const {
    state: meetingLink,
    setState: setMeetingLink,
    hasChanged: meetingLinkChanged,
    handleChangeSaved: handleMeetingLinkSaved,
  } = useStateChangeTracker("")
  const [isEditingContactInfoSection, setIsEditingContactInfoSection] =
    useState(false)
  const {
    isOpen: isSelectBrokerageDialogOpen,
    open: openSelectBrokerageDialog,
    onClosed: handleSelectBrokerageDialogClosed,
    onSelectAnotherBrokerage,
  } = useSelectBrokerageDialogState()

  const profile = useSelector(selectProfile)
  const brokerages = useSelector(selectBrokerages)
  const isProfileLoading = useSelector(profileLoading)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme()
  const [searchParams, setSearchParams] = useSearchParams()

  function handleSettingsButtonClicked() {
    navigate("/brokerage/settings/")
  }

  function handleIsEditingContactInfoChanged(isEditing, changesSaved) {
    setIsEditingContactInfoSection(isEditing)
    if (changesSaved) {
      handlePhoneSaved()
      handleAddressSaved()
      handleWebsiteSaved()
      handleMeetingLinkSaved()
    }
  }

  function handleAddBrokerageButtonClicked(e) {
    e.preventDefault()
    openSelectBrokerageDialog()
  }

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      setPhone(profile.phone || "", false)
      setWebsite(profile.website || "", false)
      setAddress(profile.address || "", false)
      setMeetingLink(profile.meeting_link || "", false)
      if (!profile.phone || !profile.email || !profile.website) {
        setIsEditingContactInfoSection(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading]
  )

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      const openSubscribeModal = searchParams.get("subscribe")
      if (openSubscribeModal) {
        dispatch(
          showProDialogForBrokerageIndex({
            index: 0,
            source: "Profile URL parameter",
          })
        )
        setSearchParams(new URLSearchParams())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading, searchParams]
  )

  return (
    <Column
      sx={{
        px: { xs: 2, sm: 8 },
        py: 4,
      }}
    >
      <RowButColumnOnMobile
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          [theme.breakpoints.only("xs")]: {
            justifyContent: "flex-start",
          },
        }}
      >
        <RowButColumnOnMobile
          sx={{
            alignItems: "center",
          }}
        >
          <UserAvatar
            isProfileLoading={isProfileLoading}
            profileData={profile}
            size={112}
          />
          <Typography
            variant="h4"
            sx={{ ml: { xs: 0, sm: 2 }, mt: { xs: 2, sm: 0 } }}
          >
            {getFullName(profile)}
          </Typography>
        </RowButColumnOnMobile>
        <Row
          sx={{
            alignItems: "center",
            mt: { xs: 4, sm: 0 },
            [theme.breakpoints.only("xs")]: {
              width: "100%",
              "& .MuiButton-root": {
                flexBasis: "50%",
              },
            },
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsEditProfileDialogOpen(true)}
          >
            Edit
          </Button>
          <EditProfileDialog
            open={isEditProfileDialogOpen}
            onClose={() => setIsEditProfileDialogOpen(false)}
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={handleSettingsButtonClicked}
            sx={{ ml: 2 }}
          >
            Settings
          </Button>
        </Row>
      </RowButColumnOnMobile>
      <TitledEditorSection
        title="Contact information"
        editButtonClicked={isEditingContactInfoSection}
        onEditChanged={handleIsEditingContactInfoChanged}
        apiURL="/customers/api/profile/"
        successCallback={(data) => dispatch(updateProfile(data))}
        valueProps={{ phone, address, website, meeting_link: meetingLink }}
      >
        <Box sx={{ mt: 4 }}>
          <Grid container spacing={4}>
            <Grid xs={12} md={6}>
              <EditableTextDisplay
                Icon={PhoneOutlinedIcon}
                editMode={isEditingContactInfoSection}
                textFieldProps={{
                  label: "Phone Number",
                  value: phone,
                  onChange: (e) => setPhone(e.target.value),
                  type: "tel",
                }}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <EditableTextDisplay
                Icon={LocationOnOutlinedIcon}
                editMode={isEditingContactInfoSection}
                textFieldProps={{
                  label: "Location",
                  value: address,
                  onChange: (e) => setAddress(e.target.value),
                }}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <EditableTextDisplay
                Icon={LanguageOutlinedIcon}
                editMode={isEditingContactInfoSection}
                textFieldProps={{
                  label: "Website",
                  value: website,
                  onChange: (e) => setWebsite(e.target.value),
                  type: "url",
                }}
                typographyProps={
                  website && {
                    text: <JumpLink title={website} url={website} />,
                  }
                }
              />
            </Grid>
            <Grid xs={12} md={6}>
              <EditableTextDisplay
                Icon={Groups2OutlinedIcon}
                editMode={isEditingContactInfoSection}
                textFieldProps={{
                  label: "Meeting Link",
                  value: meetingLink,
                  onChange: (e) => setMeetingLink(e.target.value),
                  type: "url",
                  helperText:
                    "Candidates can see this link in your conversation.",
                }}
                typographyProps={
                  meetingLink && {
                    text: <JumpLink title={meetingLink} url={meetingLink} />,
                  }
                }
              />
            </Grid>
          </Grid>
        </Box>
        <UnsavedChangesPreventLeaveDialog
          dataHasChanged={
            phoneChanged ||
            addressChanged ||
            websiteChanged ||
            meetingLinkChanged
          }
        />
      </TitledEditorSection>
      <BorderedTitledSection title="Associated brokerages">
        <Column
          sx={{
            "& > .MuiBox-root:nth-of-type(n + 2)": {
              mt: 2,
            },
          }}
        >
          {brokerages.map((brokerage, index) => (
            <AssociatedBrokerage
              key={brokerage.id}
              index={index}
              brokerage={brokerage}
            />
          ))}
          <Row
            sx={{
              alignItems: "center",
              mt: (theme) => `${theme.spacing(4)}!important`,
            }}
          >
            <IconButton
              sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
              onClick={handleAddBrokerageButtonClicked}
            >
              <AddOutlinedIcon color="primary" />
            </IconButton>
            <Link
              variant="caption"
              color="primary"
              sx={{ ml: 2 }}
              href="#"
              onClick={handleAddBrokerageButtonClicked}
            >
              Add another Brokerage Page
            </Link>
            <SelectBrokerageDialog
              open={isSelectBrokerageDialogOpen}
              onClose={() => handleSelectBrokerageDialogClosed()}
              onSelectAnotherBrokerage={() => onSelectAnotherBrokerage()}
            />
          </Row>
        </Column>
        <Divider sx={{ my: 4 }} />
        <Link
          variant="body1"
          color="text.secondary2"
          component={RouterLink}
          to="/brokerage/invoices/"
        >
          See all associated invoices
        </Link>
      </BorderedTitledSection>
    </Column>
  )
}

function AssociatedBrokerage({ index, brokerage }) {
  const [menuAnchorElem, setMenuAnchorElem] = useState(null)
  const [isSwitchPlanDialogOpen, setIsSwitchPlanDialogOpen] = useState(false)
  const [isCancelSubscriptionDialogOpen, setIsCancelSubscriptionDialogOpen] =
    useState(false)
  const [isRestoreSubscriptionDialogOpen, setIsRestoreSubscriptionDialogOpen] =
    useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"))
  const { isBrokerageSubscribed } = useIsBrokerageSubscribed()

  const subscription = electActiveSubscriptionForBrokerage(brokerage, true)
  const isBrokerageSubscribedForFact = isBrokerageSubscribed(brokerage)
  const isFreeRiding = useSelector(selectIsFreeRiding)

  function handleMenuButtonClicked(e) {
    setMenuAnchorElem(e.target)
  }

  function handleEditMenuItemClicked() {
    handleMenuClosed()
    navigate(`/brokerage/${brokerage.id}/edit`)
  }

  function handleMenuClosed() {
    setMenuAnchorElem(null)
  }

  function showUpgradeProDialog() {
    dispatch(showProDialogForBrokerageIndex({ index, source: "Profile page" }))
  }

  function handleSwitchToBasicMenuItemClicked() {
    handleMenuClosed()
    setIsCancelSubscriptionDialogOpen(true)
  }

  function handleRestoreSubscriptionButtonClicked() {
    setIsRestoreSubscriptionDialogOpen(true)
    handleMenuClosed()
  }

  function handleSwitchPlanMenuItemClicked() {
    handleMenuClosed()
    setIsSwitchPlanDialogOpen(true)
  }

  let menuItems = [
    <MenuItem key="a" onClick={handleEditMenuItemClicked}>
      <ListItemIcon>
        <EditOutlinedIcon color="primary" />
      </ListItemIcon>
      <ListItemText>View/edit brokerage</ListItemText>
    </MenuItem>,
  ]
  if (subscription) {
    if (subscription.status === SUBSCRIPTION_STATUSES.active) {
      const SwitchPlanMenuItem = (
        <MenuItem key="b" onClick={handleSwitchPlanMenuItemClicked}>
          <ListItemIcon>
            <TodayOutlinedIcon color="primary" />
          </ListItemIcon>
          <ListItemText>
            Switch to {getTogglePlanData(subscription).code} plan
          </ListItemText>
        </MenuItem>
      )
      menuItems.push(SwitchPlanMenuItem)
      menuItems.push(<Divider key="c" />)
      if (subscription.cancel_at) {
        menuItems.push(
          <MenuItem key="d" onClick={handleRestoreSubscriptionButtonClicked}>
            <ListItemIcon>
              <ReplayOutlinedIcon color="primary" />
            </ListItemIcon>
            <ListItemText>Restore Pro</ListItemText>
          </MenuItem>
        )
      } else {
        menuItems.push(
          <MenuItem key="e" onClick={handleSwitchToBasicMenuItemClicked}>
            <ListItemIcon>
              <ArrowCircleDownIcon color="primary" />
            </ListItemIcon>
            <ListItemText>Switch back to basic</ListItemText>
          </MenuItem>
        )
      }
    }
  }

  return (
    <>
      {isMobile ? (
        <Column>
          <Row>
            <BrandAvatar brand={{ icon: brokerage.image }} />
            <Column sx={{ ml: 2, width: "100%" }}>
              <Row
                sx={{
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Column sx={{ alignItems: "flex-start" }}>
                  <SubscriptionStatusChip brokerage={brokerage} />
                  <BrokerageLink
                    component={RouterLink}
                    variant="h6"
                    to={`/brokerage/${brokerage.id}/edit`}
                  >
                    {brokerage.company}
                  </BrokerageLink>
                  <Typography
                    variant="subtitle1"
                    sx={{ display: "flex", alignItems: "center", mt: 1 }}
                  >
                    <LocationOnOutlinedIcon
                      sx={{
                        color: "text.secondary2",
                        mr: 0.5,
                        fontSize: "1.1em",
                      }}
                    />
                    {brokerage.address}
                  </Typography>
                  {isFreeRiding ? (
                    <Typography variant="h6" fontSize="1.1em">
                      Free
                    </Typography>
                  ) : (
                    isBrokerageSubscribedForFact && (
                      <Column sx={{ alignItems: "flex-start" }}>
                        {subscription.cancel_at ? (
                          <>
                            <Button
                              variant="text"
                              startIcon={
                                <ReplayOutlinedIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              }
                              onClick={handleRestoreSubscriptionButtonClicked}
                            >
                              Restore subscription
                            </Button>
                            <RestoreSubscriptionDialog
                              open={isRestoreSubscriptionDialogOpen}
                              onClose={() =>
                                setIsRestoreSubscriptionDialogOpen(false)
                              }
                              brokerage={brokerage}
                            />
                            <Typography variant="subtitle1" fontSize="0.9em">
                              Access to pro features until{" "}
                              {formatDate(subscription.cancel_at)}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <AssociatedBrokerageHeaderText variant="h6">
                              ${subscription.final_price.toLocaleString()}/
                              {subscription.period}
                            </AssociatedBrokerageHeaderText>
                            <Typography variant="subtitle1">
                              Next billing on{" "}
                              {formatDate(subscription.valid_until)}
                            </Typography>
                          </>
                        )}
                      </Column>
                    )
                  )}
                </Column>
                <IconButton onClick={handleMenuButtonClicked} sx={{ ml: 1 }}>
                  <MoreVertIcon />
                </IconButton>
              </Row>
              {!isBrokerageSubscribedForFact && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={showUpgradeProDialog}
                  sx={{ mt: 2 }}
                  fullWidth
                >
                  Upgrade to Pro
                </Button>
              )}
            </Column>
          </Row>
          <Divider sx={{ mt: 2 }} />
        </Column>
      ) : (
        <Row
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Row
            sx={{
              alignItems: "center",
            }}
          >
            <BrandAvatar brand={{ icon: brokerage.image }} />
            <Column
              sx={{
                ml: 2,
              }}
            >
              <BrokerageLink
                component={RouterLink}
                variant="h6"
                to={`/brokerage/${brokerage.id}/edit`}
              >
                {brokerage.company}
                <SubscriptionStatusChip brokerage={brokerage} />
              </BrokerageLink>
              <Typography
                variant="subtitle1"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <LocationOnOutlinedIcon
                  sx={{ color: "text.secondary2", mr: 0.5, fontSize: "1.1em" }}
                />
                {brokerage.address}
              </Typography>
            </Column>
          </Row>
          <Row
            sx={{
              alignItems: "center",
              ml: 2,
            }}
          >
            {isFreeRiding ? (
              <Typography variant="h6">Free</Typography>
            ) : isBrokerageSubscribedForFact ? (
              <Column sx={{ alignItems: "flex-end" }}>
                {subscription.cancel_at ? (
                  <>
                    <Button
                      variant="text"
                      startIcon={
                        <ReplayOutlinedIcon color="primary" fontSize="small" />
                      }
                      onClick={handleRestoreSubscriptionButtonClicked}
                    >
                      Restore subscription
                    </Button>
                    <RestoreSubscriptionDialog
                      open={isRestoreSubscriptionDialogOpen}
                      onClose={() => setIsRestoreSubscriptionDialogOpen(false)}
                      brokerage={brokerage}
                    />
                    <Typography variant="subtitle1" fontSize="0.9em">
                      Access to pro features until{" "}
                      {formatDate(subscription.cancel_at)}
                    </Typography>
                  </>
                ) : (
                  <>
                    <AssociatedBrokerageHeaderText variant="h6">
                      ${subscription.final_price.toLocaleString()}/
                      {subscription.period}
                    </AssociatedBrokerageHeaderText>
                    <Typography variant="subtitle1">
                      Next billing on {formatDate(subscription.valid_until)}
                    </Typography>
                  </>
                )}
              </Column>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={showUpgradeProDialog}
              >
                Upgrade to Pro
              </Button>
            )}
            <IconButton onClick={handleMenuButtonClicked} sx={{ ml: 1 }}>
              <MoreVertIcon />
            </IconButton>
          </Row>
        </Row>
      )}
      <Menu
        anchorEl={menuAnchorElem}
        open={!!menuAnchorElem}
        onClose={handleMenuClosed}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        {menuItems}
      </Menu>
      <SwitchPlanDialog
        open={isSwitchPlanDialogOpen}
        onClose={() => setIsSwitchPlanDialogOpen(false)}
        brokerage={brokerage}
      />
      <CancelSubscriptionDialog
        open={isCancelSubscriptionDialogOpen}
        onClose={() => setIsCancelSubscriptionDialogOpen(false)}
        brokerage={brokerage}
      />
      <PaymentStatusDialog
        brokerage={brokerage}
        onTrySubscribeAgain={showUpgradeProDialog}
      />
    </>
  )
}

const AssociatedBrokerageHeaderText = styled(Typography)(() => ({
  fontSize: "1.1em",
}))

const BrokerageLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "1.1em",
  [theme.breakpoints.only("xs")]: {
    marginTop: theme.spacing(1),
  },
  "& .MuiChip-root": {
    marginLeft: theme.spacing(1),
  },
}))

import QuickreplyOutlinedIcon from "@mui/icons-material/QuickreplyOutlined"
import LoadingButton from "@mui/lab/LoadingButton"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import Column from "../../../common/components/Column"
import { useAuthorizedAxiosClient, useIsMobile } from "../../../common/utils"
import AutoMessageTextField, {
  useAutoMessageTextFieldState,
} from "../components/AutoMessageTextField"
import { profileLoading, updateProfileProperty } from "../slice"

export default function ForcedAddAutoMessageDialog({ open, onClose }) {
  const {
    text,
    setText,
    textInputRef,
    autoCompleteMenuPos,
    setAutoCompleteMenuPos,
    handleTextChanged,
    handleAutoCompleteOptionSelected,
  } = useAutoMessageTextFieldState()
  const [isLoading, setIsLoading] = useState(false)
  const isProfileLoading = useSelector(profileLoading)
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const axios = useAuthorizedAxiosClient()

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      setIsLoading(true)
      axios
        .get("/customers/api/text-message-template/suggested/")
        .then(({ data }) => setText(data.message))
        .finally(() => setIsLoading(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading]
  )

  function handleAddAutoMessageButtonClicked() {
    setIsLoading(true)
    axios
      .post("/customers/api/text-message-template/?forced=1", { text })
      .then(() => {
        dispatch(updateProfileProperty({ created_forced_auto_message: true }))
        onClose(true)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Dialog
      open={open}
      onClose={null}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogContent>
        <Column sx={{ gap: 3 }}>
          <Column sx={{ alignItems: "center", textAlign: "center", gap: 4 }}>
            <HaloCircularIcon Icon={QuickreplyOutlinedIcon} size={64} />
            <Typography variant="h4">Create your first auto-message</Typography>
          </Column>
          <Typography variant="body1">
            Auto-messages are sent immediately to inquiring agents, ensuring you
            maximize your recruiting opportunities.
          </Typography>
          <Typography variant="body1" color="text.secondary2" sx={{ mt: -2 }}>
            Don't want an auto-message? You can change this in your settings at
            any time.
          </Typography>
          <AutoMessageTextField
            text={text}
            textInputRef={textInputRef}
            autoCompleteMenuPos={autoCompleteMenuPos}
            setAutoCompleteMenuPos={setAutoCompleteMenuPos}
            handleTextChanged={handleTextChanged}
            handleAutoCompleteOptionSelected={handleAutoCompleteOptionSelected}
          />
        </Column>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          disabled={!text}
          loading={isLoading}
          onClick={handleAddAutoMessageButtonClicked}
          fullWidth
          sx={{ flexBasis: "100%!important" }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { BuildingVerticalSplitLayout } from "../../../../common/components/VerticalSplitLayout"
import { axiosClient } from "../../../../common/utils"
import { WebhookPayload, webhookTrack } from "../../../agent/utils"
import { USER_TYPES, mixpanelTrackSignupWizardFinished } from "../../utils"
import SetPasswordPage from "../Page"

export default function AgentSetSignupPassword() {
  const [studentData, setStudentData] = useState({})
  const searchParams = useSearchParams()[0]
  const agentIsStudent = !!searchParams.get("student")

  function handlePasswordSet() {
    if (agentIsStudent) {
      webhookTrackStudentData("Finished", studentData)
      mixpanelTrackSignupWizardFinished(USER_TYPES.agent)
    }
  }

  useEffect(
    () => {
      if (agentIsStudent) {
        axiosClient
          .get(`/agents/api/student-data/${searchParams.get("key")}/`)
          .then(({ data }) => {
            setStudentData(data)
            webhookTrackStudentData("Landing", data)
          })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams]
  )

  return (
    <SetPasswordPage
      WrapperLayout={BuildingVerticalSplitLayout}
      onSuccess={handlePasswordSet}
    />
  )
}

function webhookTrackStudentData(stepSubtitle, data) {
  webhookTrack(
    new WebhookPayload(
      `Student Set Password: ${stepSubtitle}`,
      data.first_name,
      data.last_name,
      data.email,
      data.phone,
      data.zip_code
    )
  )
}

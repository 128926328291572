import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined"
import { styled } from "@mui/material/styles"
import { useSelector } from "react-redux"

import BorderedChip, {
  PurpleBorderedChip,
} from "../../../common/components/BorderedChips"
import { selectIsFreeRiding } from "../slice"
import { useIsBrokerageSubscribed } from "../utils"

const BasicSubscriptionChip = styled(BorderedChip)(({ theme }) => ({
  color: theme.palette.text.secondary2,
  borderColor: theme.palette.text.secondary2,
}))

export default function SubscriptionStatusChip({ brokerage, addIcon }) {
  const { isBrokerageSubscribed } = useIsBrokerageSubscribed()
  const isFreeRiding = useSelector(selectIsFreeRiding)
  if (isBrokerageSubscribed(brokerage)) {
    return (
      <PurpleBorderedChip
        color="primary"
        label={isFreeRiding ? "Free" : "Pro"}
        size="small"
        icon={addIcon ? <WorkspacePremiumOutlinedIcon /> : undefined}
      />
    )
  }
  return <BasicSubscriptionChip label="Basic" size="small" />
}

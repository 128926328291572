import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"
import { useState } from "react"
import { useDispatch } from "react-redux"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import LoadingButton from "../../../common/components/LoadingButton"
import { useAuthorizedAxiosClient } from "../../../common/utils"
import { updateBrokerageSubscription } from "../slice"
import { electActiveSubscriptionForBrokerage, formatDate } from "../utils"

export default function RestoreSubscriptionDialog({
  open,
  onClose,
  brokerage,
}) {
  const [isRestoringSubscription, setIsRestoringSubscription] = useState(false)

  const axios = useAuthorizedAxiosClient()
  const dispatch = useDispatch()

  function handleRestoreButtonClicked() {
    setIsRestoringSubscription(true)
    axios
      .post(`/billing/api/${brokerage.id}/renew-subscription/`)
      .then(({ data }) => {
        dispatch(
          updateBrokerageSubscription({
            brokerageID: brokerage.id,
            subscription: data,
          })
        )
        onClose()
      })
      .finally(() => setIsRestoringSubscription(false))
  }

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="xs" fullWidth>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <HaloCircularIcon Icon={ReplayOutlinedIcon} />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Restore Pro subscription
        </Typography>
        <Typography variant="body1" color="text.secondary2" sx={{ mt: 1 }}>
          Restoring your Pro subscription won't trigger any additional invoices
          on your card. Your current subscription period will continue until{" "}
          {formatDate(
            electActiveSubscriptionForBrokerage(brokerage, true).valid_until
          )}
          .
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => onClose()}
          size="large"
        >
          Cancel
        </Button>
        <LoadingButton
          isLoading={isRestoringSubscription}
          onClick={handleRestoreButtonClicked}
          size="large"
        >
          Restore Pro
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

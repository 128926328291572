import FireIconOutlined from "@mui/icons-material/LocalFireDepartmentOutlined"
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined"
import Divider from "@mui/material/Divider"
import Paper from "@mui/material/Paper"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { AGENT_LEVELS_OF_INTEREST } from "../../pages/agent/utils"
import AgentStatusChip from "../../pages/brokerage/components/AgentStatusChip"
import AgentToMarketStatComparison, {
  buildStatTitle,
  salesVolumeTooltip,
  yearsInBusinessTooltip,
} from "../../pages/brokerage/components/AgentToMarketStatComparison"
import { AGENT_FIELD_HIDDEN_MARKER } from "../../pages/brokerage/utils"
import { formatCurrency } from "../utils"
import {
  GrayBorderedChip,
  PurpleBorderedChip,
  RedBorderedChip,
} from "./BorderedChips"
import Column from "./Column"
import Row from "./Row"

export const CANDIDATE_CARD_WIDTH = 18
export const CANDIDATE_CARD_HEIGHT = 28

export const CandidateCardContainer = styled(Paper)(() => ({
  display: "flex",
  flexDirection: "column",
  p: 2,
  width: `${CANDIDATE_CARD_WIDTH}em`,
  position: "absolute",
  transition: "left 0.5s, transform 0.5s, opacity 0.5s",
}))

export default function CandidateCard({
  isHot,
  requestStatus,
  agentName,
  levelOfInterest,
  yearInBusiness,
  salesVolumeLastYear,
  signingBonus,
  stats,
  ActionsRow,
  Avatar,
  styles,
}) {
  const signingBonusHasValue =
    signingBonus && signingBonus !== AGENT_FIELD_HIDDEN_MARKER
  const SigningBonusChip = signingBonusHasValue
    ? PurpleBorderedChip
    : GrayBorderedChip

  return (
    <CandidateCardContainer
      elevation={0}
      sx={{
        ...styles,
      }}
    >
      <Row
        sx={{
          justifyContent: "space-between",
        }}
      >
        {isHot ? (
          <Tooltip title="This agent is 50% more engaged with the platform than other agents">
            <RedBorderedChip
              label={
                <Row sx={{ alignItems: "center" }}>
                  Hot <FireIconOutlined sx={{ fontSize: "1em", ml: 0.25 }} />
                </Row>
              }
              size="small"
            />
          </Tooltip>
        ) : (
          <span />
        )}
        <AgentStatusChip
          agent={{ status: requestStatus, created: new Date().toISOString() }}
        />
      </Row>
      <Column
        sx={{
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {Avatar}
        <Typography variant="h6" sx={{ mt: 2 }}>
          {agentName}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ display: "flex", alignItems: "center", mt: 1 }}
        >
          <TravelExploreOutlinedIcon color="text.secondary2" sx={{ mr: 1 }} />
          {AGENT_LEVELS_OF_INTEREST[levelOfInterest] ||
            "Unknown level of interest"}
        </Typography>
      </Column>
      <CardSectionDivider />
      <Row
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
        }}
      >
        <AgentToMarketStatComparison
          title={buildStatTitle(
            yearInBusiness,
            (yearInBusiness) => `${yearInBusiness} years`
          )}
          subtitle="Experience"
          agentValue={yearInBusiness}
          marketValue={stats.yearsInBusiness}
          tooltip={yearsInBusinessTooltip(stats.yearsInBusiness)}
        />
        <AgentToMarketStatComparison
          title={buildStatTitle(salesVolumeLastYear, (salesVolume) =>
            formatCurrency(salesVolume)
          )}
          subtitle="Total Sales"
          agentValue={salesVolumeLastYear}
          marketValue={stats.salesVolume}
          tooltip={salesVolumeTooltip(stats.salesVolume)}
        />
        {!yearInBusiness && !salesVolumeLastYear && (
          <Column
            sx={{
              alignItems: "center",
              position: "absolute",
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              backdropFilter: "blur(1px)",
            }}
          >
            <Typography variant="body1" textAlign="center">
              Agent may reveal sales statistics upon request
            </Typography>
          </Column>
        )}
      </Row>
      <CardSectionDivider />
      <Row sx={{ justifyContent: "center", alignItems: "center" }}>
        <Typography variant="subtitle1" color="text.secondary2">
          Desired Signing Bonus:
        </Typography>
        <SigningBonusChip
          label={
            signingBonusHasValue ? formatCurrency(signingBonus) : "Unknown"
          }
          sx={{ ml: 1 }}
        />
      </Row>
      {ActionsRow}
    </CandidateCardContainer>
  )
}

const CardSectionDivider = styled(Divider)(({ theme }) => ({
  marginBlock: theme.spacing(2),
}))
